import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import './AdminDashboard.css';

const AdminDashboard = ({ userData, onLogout }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const selectedConversationRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const socket = useRef(null);

  // useEffect(() => {
  //   socket.current = io('https://backoffice.gourdefriendly.green');

  //   socket.current.on('new_feedback_message', (newMessageWrapper) => {
  //     console.log('Received new message:', newMessageWrapper);
  //     const newMessage = newMessageWrapper.data;
  //     if (newMessage && newMessage.id && newMessage.created_at) {
  //       if (newMessage.user_id !== '167' && newMessage.beta_conversation_id === selectedConversationRef.current) {
  //         setMessages((prevMessages) => [
  //           ...prevMessages,
  //           {
  //             id: newMessage.id.toString(),
  //             user_id: newMessage.user_id || 'unknown',
  //             content: newMessage.content || 'No content',
  //             user_first_name: 'User',
  //             user_name: '',
  //             created_at: newMessage.created_at.toString() || new Date().toISOString(),
  //             beta_conversation_id: newMessage.beta_conversation_id,
  //           }
  //         ]);

  //         // Mettre à jour le nombre de messages non lus
  //         setConversations((prevConversations) => prevConversations.map((conv) => {
  //           if (conv.id === newMessage.beta_conversation_id) {
  //             return { ...conv, unread_count: (conv.unread_count || 0) + 1 };
  //           }
  //           return conv;
  //         }));
  //       } else {
  //         // Mettre à jour le nombre de messages non lus pour les autres conversations
  //         setConversations((prevConversations) => prevConversations.map((conv) => {
  //           if (conv.id === newMessage.beta_conversation_id) {
  //             return { ...conv, unread_count: (conv.unread_count || 0) + 1 };
  //           }
  //           return conv;
  //         }));
  //       }
  //     } else {
  //       console.error('Received message with missing properties:', newMessage);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const initializeSocket = () => {
      if (socket.current) {
        socket.current.disconnect();
      }

      socket.current = io('https://backoffice.gourdefriendly.green');

      socket.current.on('connect', () => {
        console.log('Connected to server');
        if (selectedConversationRef.current) {
          socket.current.emit('join_feedback', { beta_conversation_id: selectedConversationRef.current });
        }
      });

      socket.current.on('new_feedback_message', (newMessageWrapper) => {
        console.log('Received new message:', newMessageWrapper);
        const newMessage = newMessageWrapper.data;
        if (newMessage && newMessage.id && newMessage.created_at) {
          if (newMessage.user_id !== '167' && newMessage.beta_conversation_id === selectedConversationRef.current) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                id: newMessage.id.toString(),
                user_id: newMessage.user_id || 'unknown',
                content: newMessage.content || 'No content',
                user_first_name: 'User',
                user_name: '',
                created_at: newMessage.created_at.toString() || new Date().toISOString(),
                beta_conversation_id: newMessage.beta_conversation_id,
              }
            ]);

            // Mettre à jour le nombre de messages non lus
            setConversations((prevConversations) => prevConversations.map((conv) => {
              if (conv.id === newMessage.beta_conversation_id) {
                return { ...conv, unread_count: (conv.unread_count || 0) + 1 };
              }
              return conv;
            }));
          } else {
            // Mettre à jour le nombre de messages non lus pour les autres conversations
            setConversations((prevConversations) => prevConversations.map((conv) => {
              if (conv.id === newMessage.beta_conversation_id) {
                return { ...conv, unread_count: (conv.unread_count || 0) + 1 };
              }
              return conv;
            }));
          }
        } else {
          console.error('Received message with missing properties:', newMessage);
        }
      });

      socket.current.on('disconnect', () => {
        console.log('Disconnected from server');
      });

      socket.current.on('connect_error', (error) => {
        console.error('Connection error:', error);
        // Essayez de vous reconnecter après une pause
        setTimeout(initializeSocket, 5000);
      });
    };

    initializeSocket();

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);


  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await axios.get('https://backoffice.gourdefriendly.green/beta/admin/conversations_with_unread_counts');
        setConversations(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des conversations:', error);
      }
    };

    fetchConversations();
  }, []);

  const fetchMessages = async (conversationId, userId) => {
    try {
      const response = await axios.get(`https://backoffice.gourdefriendly.green/beta/admin/conversations/${conversationId}/messages`);
      const filteredMessages = response.data.filter(msg => msg.user_id !== '167');
      setMessages(filteredMessages.map((msg) => ({
        ...msg,
        id: msg.id ? msg.id.toString() : 'unknown',
      })));
      setSelectedConversation(conversationId);
      selectedConversationRef.current = conversationId;
      setSelectedUserId(userId);

      socket.current.emit('join_feedback', { beta_conversation_id: conversationId });
      console.log(`Joining conversation room: ${conversationId}`);

      // Marquer les messages comme lus
      setConversations((prevConversations) => prevConversations.map((conv) => {
        if (conv.id === conversationId) {
          return { ...conv, unread_count: 0 };
        }
        return conv;
      }));
    } catch (error) {
      console.error(`Erreur lors de la récupération des messages pour la conversation ${conversationId}:`, error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
    try {
      const response = await axios.post('https://backoffice.gourdefriendly.green/beta/messages', {
        beta_conversation_id: selectedConversation,
        content: newMessage,
        user_id: '167',
      });
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: response.data.beta_message_id.toString(),
          user_id: '167',
          content: newMessage,
          user_first_name: 'Admin',
          user_name: '',
          created_at: new Date().toISOString(),
          beta_conversation_id: selectedConversation,
        }
      ]);
      setNewMessage('');

      // Mettre à jour le nombre de messages non lus
      setConversations((prevConversations) => prevConversations.map((conv) => {
        if (conv.id === selectedConversation) {
          return { ...conv, unread_count: 0 };
        }
        return conv;
      }));
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message:', error);
    }
  };

  return (
    <div className="dashboard-container">
      <div className="top-bar">
        <button onClick={onLogout} className="logout-button">Déconnexion</button>
      </div>
      <div className="content">
        <div className="conversations-list">
          <h3>Conversations</h3>
          <ul>
            {conversations.length > 0 ? (
              conversations.map((conv) => (
                <li key={conv.id} onClick={() => fetchMessages(conv.id, conv.user_id)} className="conversation-item">
                  Conversation avec {conv.user_first_name} {conv.user_name} ({conv.unread_count} non lus)
                </li>
              ))
            ) : (
              <li>Aucune conversation disponible</li>
            )}
          </ul>
        </div>
        <div className="messages-list">
          {selectedConversation ? (
            <>
              <h3>Messages dans la conversation {selectedConversation}</h3>
              <ul>
                {messages.length > 0 ? (
                  messages.map((msg) => (
                    <li key={msg.id} className={`message-item ${msg.user_id === selectedUserId ? 'user-message' : 'admin-message'}`}>
                      <div className="message-info">
                        <strong>{msg.user_first_name} {msg.user_name}</strong>
                      </div>
                      <span>{msg.content}</span>
                    </li>
                  ))
                ) : (
                  <li>Aucun message dans cette conversation</li>
                )}
              </ul>
              <div className="message-input-container">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Écrire un message..."
                  className="message-input"
                />
                <button onClick={handleSendMessage} className="send-button">Envoyer</button>
              </div>
            </>
          ) : (
            <p>Sélectionnez une conversation pour voir les messages</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
